import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Navbar, Sidebar, Footer, LoadingMessage } from "../components";
import { useStateContext } from "../contexts/ContextProvider";

import { Breadcrumb, Layout, Menu, Button, Divider } from "antd";
import { ArrowsAltOutlined, ArrowLeftOutlined } from "@ant-design/icons";

import { AiOutlineShoppingCart } from "react-icons/ai";
import { MdOutlineInventory2 } from "react-icons/md";
import { FaRegIdCard, FaBoxArchive, FaCartShopping } from "react-icons/fa6";
import { CiRoute, CiDeliveryTruck } from "react-icons/ci";
import { PiSignOutBold } from "react-icons/pi";
import { IoIosHelpCircle } from "react-icons/io";
import { FiShoppingBag } from "react-icons/fi";
import { RiTableAltFill } from "react-icons/ri";

import {
  RiShoppingCart2Fill,
  RiShoppingCart2Line,
  RiContactsFill,
  RiContactsLine,
} from "react-icons/ri";
import { BsBox2Fill, BsBox2 } from "react-icons/bs";

import { FaRegUser, FaUser } from "react-icons/fa";

import { HiTruck, HiOutlineTruck } from "react-icons/hi2";

import { PiSteeringWheelFill, PiSteeringWheel } from "react-icons/pi";

import {
  TbLayoutSidebarRightCollapseFilled,
  TbLayoutSidebarRightExpandFilled,
} from "react-icons/tb";

import isotipo from "../data/Isotipo.png";

const { Header, Content, Sider } = Layout;

const breadcrumbMap = {
  "/": [{ name: "Home", link: "/" }],
  "/home": [{ name: "Home", link: "/" }],
  "/orders": [
    { name: "Home", link: "/" },
    { name: "Orders", link: "/orders" },
  ],
  "/orders/:id": [
    { name: "Home", link: "/" },
    { name: "Orders", link: "/orders" },
    { name: "Order Details" },
  ],
  "/products": [
    { name: "Home", link: "/" },
    { name: "Products", link: "/products" },
  ],
  "/products/create": [
    { name: "Home", link: "/" },
    { name: "Products", link: "/products" },
    { name: "Create Product" },
  ],
  "/products/:product_id": [
    { name: "Home", link: "/" },
    { name: "Products", link: "/products" },
    { name: "Product Details" },
  ],
  "/sellers": [
    { name: "Home", link: "/" },
    { name: "Sellers", link: "/sellers" },
  ],
  "/clients": [
    { name: "Home", link: "/" },
    { name: "Clients", link: "/clients" },
  ],
  "/drivers": [
    { name: "Home", link: "/" },
    { name: "Drivers", link: "/drivers" },
  ],
  "/makeroute": [
    { name: "Home", link: "/" },
    { name: "Make Route", link: "/makeroute" },
  ],
  "/dashboardDailyRoute/:routeDay": [
    { name: "Home", link: "/" },
    { name: "Dashboard Daily Route" },
  ],
  "/notFoundPage": [{ name: "404", link: "/notFoundPage" }],
};

const links = [
  {
    title: "Home",
    spaTitle: "Home",
    links: [
      {
        name: "home",
        spaName: "Menú Principal",
        icon: <FiShoppingBag />,
      },
    ],
  },

  {
    title: "Pages",
    spaTitle: "Páginas",
    links: [
      {
        name: "orders",
        spaName: "Pedidos",
        icon: <HiTruck />,
      },
      {
        name: "products",
        spaName: "Productos",
        icon: <FaBoxArchive />,
      },
      // {
      //   name: "sellers",
      //   spaName: "Vendedores",
      //   icon: <RiContactsLine />,
      // },
      // {
      //   name: "clients",
      //   spaName: "Clientes",
      //   icon: <FaRegIdCard />,
      // },
      // {
      //   name: "drivers",
      //   spaName: "Choferes",
      //   icon: <CiDeliveryTruck />,
      // },
    ],
  },
  {
    title: "Apps",
    spaTitle: "Aplicaciones",
    links: [
      {
        name: "makeroute",
        spaName: "Hacer Ruta",
        icon: <CiRoute />,
      },
      // {
      //   name: "reportgeneration",
      //   spaName: "Reportes",
      //   icon: <RiTableAltFill />,
      // },
    ],
  },
];

const sizeIconsSmallDevices = "w-4 h-4";

const linksSmallDevices = [
  {
    name: "orders",
    spaName: "Pedidos",
    iconInactive: <HiOutlineTruck className="w-5 h-5" />,
    iconActive: <HiTruck className="w-5 h-5" />,
  },
  {
    name: "products",
    spaName: "Productos",
    iconInactive: <BsBox2 className={sizeIconsSmallDevices} />,
    iconActive: <BsBox2Fill className={sizeIconsSmallDevices} />,
  },
  {
    name: "sellers",
    spaName: "Vendedores",
    iconInactive: <RiContactsLine className={sizeIconsSmallDevices} />,
    iconActive: <RiContactsFill className={sizeIconsSmallDevices} />,
  },
  {
    name: "clients",
    spaName: "Clientes",
    iconInactive: <FaRegUser className={sizeIconsSmallDevices} />,
    iconActive: <FaUser className={sizeIconsSmallDevices} />,
  },
  {
    name: "drivers",
    spaName: "Choferes",
    iconInactive: <PiSteeringWheel className="w-5 h-5" />,
    iconActive: <PiSteeringWheelFill className="w-5 h-5" />,
  },
];

const DashboardLayout = ({ children }) => {
  const { activeMenu, loadingMessage } = useStateContext();
  const [isSideBarCollaped, setIsSideBarCollaped] = useState(false);

  const handleClickSideBarCollapsed = () => {
    setIsSideBarCollaped(!isSideBarCollaped);
  };

  const location = useLocation();
  const path = location.pathname;

  // Get the breadcrumb items for the current path
  const breadcrumbItems = breadcrumbMap[path] || [
    { name: "Home", link: "/" },
    { name: "Not Found" },
  ];

  const borderRadius = "15";

  const items2 = links.map((item, index) => {
    const key = String(index + 1);

    return {
      key: key,
      label: item.spaTitle,
      children: item.links.map((link, index) => {
        return (
          <Menu.Item icon={link.icon}>
            <Link to={`/${link.name}`}>{link.spaName}</Link>
          </Menu.Item>
        );
      }),
    };
  });
  useEffect(() => {
    console.log("items2", items2);
  }, []);

  return (
    // <div className="flex relative dark:bg-main-dark-bg">
    //   <div
    //     className={
    //       activeMenu
    //         ? "w-72 block fixed sidebar bg-white transition-all duration-250 ease-in-out"
    //         : "w-0"
    //     }
    //     style={{ zIndex: "1000" }}
    //   >
    //     <Sidebar />
    //   </div>
    //   <div
    //     className={`dark:bg-main-bg bg-main-bg min-h-screen w-full transition-all duration-250 ease-in-out ${
    //       activeMenu ? "lg:ml-72 lg:w-[calc(100%-18rem)]" : "flex-2"
    //     }`}
    //   >
    //     <div className="bg-main-bg dark:bg-main-dark-bg-nav w-full">
    //       <Navbar
    //         className={`${activeMenu ? "lg:w-[calc(100%-18rem)]" : "w-full"}`}
    //       />
    //       {loadingMessage && <LoadingMessage activeMenu={activeMenu} />}
    //     </div>
    //     <div>{children}</div>
    //     <Footer />
    //   </div>
    // </div>

    <Layout style={{ height: "100vh" }} className="sm:px-1 sm:py-3">
      <Content className="sm:px-5">
        <Layout
          style={{
            flex: 1,
            borderRadius: `${borderRadius}px ${borderRadius}px ${borderRadius}px ${borderRadius}px`,
          }}
        >
          <div className="bg-transparent flex">
            {/* NavBar smalldevices */}
            <div
              className="fixed bottom-0 left-0 w-full bg-red-white sm:hidden h-14 flex justify-between px-4 bg-white shadow-2xl"
              style={{ zIndex: 10000 }}
            >
              {linksSmallDevices.map((item) => (
                <NavLink
                  to={item.name}
                  className={({ isActive }) =>
                    `transition-all duration-200 ease-in-out flex items-center justify-center w-full m-3 rounded-lg flex-col text-slate-700 ${
                      isActive ? "   focus:text-slate-800" : "bg-transparent"
                    }`
                  }
                >
                  {({ isActive }) =>
                    isActive ? item.iconActive : item.iconInactive
                  }
                </NavLink>
              ))}
            </div>

            {/* Sidebar */}
            <div
              className={`hidden sm:flex sm:flex-col my-3 transition-all duration-300 ease-in-out ${
                isSideBarCollaped ? "w-14" : " w-44"
              } `}
            >
              <Link
                to="/home"
                className=" text-slate-900 text-lg font-bold sm:block hidden"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <div className="flex items-center space-x-2 text-center justify-start ml-3">
                  <img className="w-8 h-8 rounded-md" src={isotipo} />{" "}
                  <span
                    className={`transition-all duration-300 ease-in-out whitespace-nowrap ${
                      isSideBarCollaped ? "opacity-0" : "opcity-100"
                    }`}
                  >
                    Co-Business
                  </span>
                </div>
              </Link>
              <div
                className={`sm:flex hidden transition-all duration-100 ease-in-out justify-end rounded-lg space-x-2 items-center min-h-8 ${
                  isSideBarCollaped ? "px-2.5" : "mr-0"
                }`}
              >
                <Button
                  onClick={handleClickSideBarCollapsed}
                  type="link"
                  className="text-gray-400 text-xs p-0 m-0"
                >
                  <div className="flex items-center">
                    <div
                      className={`lg:whitespace-nowrap lg:transition-all lg:duration-100 lg:ease-in-out  ${
                        isSideBarCollaped ? "opacity-0 w-0" : "opacity-100 w-14"
                      }`}
                    >
                      Colapsar
                    </div>
                    {isSideBarCollaped ? (
                      <TbLayoutSidebarRightCollapseFilled className="w-5 h-5 " />
                    ) : (
                      <TbLayoutSidebarRightExpandFilled className="w-5 h-5 " />
                    )}
                  </div>
                </Button>
                <div
                  className={`transtion-all duration-300 ease-in-out flex items-center justify-between ${
                    isSideBarCollaped ? "opacity-0" : "opacity-100 "
                  }`}
                ></div>
              </div>
              <Divider className="sm:block hidden" />
              <div
                className="hidden sm:flex flex-col justify-between my-3 sm:h-[80.7vh] "
                // style={{ height: "80.7vh" }}
              >
                <div>
                  {links.map((item, i) => (
                    <>
                      {/* <div
                        className={`text-gray-500 transition-all duration-100 ease-in-out ${
                          isSideBarCollaped ? "opacity-" : "text-normal"
                        }`}
                      >
                        {item.spaTitle}
                      </div> */}
                      {item.links.map((link, i) => (
                        <NavLink
                          to={link.name}
                          className={({ isActive }) =>
                            `transition-all duration-100 ease-in-out px-2 py-1 rounded-lg m-2 flex space-x-2 items-center min-h-8  ${
                              isActive
                                ? `
                         shadow-sm bg-white outline hover:text-slate-800 outline-gray-200 outline-1 font-bold text-slate-700 focus:outline-1 focus:outline-gray-200 focus:outline `
                                : "hover:bg-white hover:text-gray-700 text-gray-400 "
                            }}`
                          }
                        >
                          <div className="pl-1">{link.icon}</div>
                          <div>
                            <div
                              className={`transtion-all duration-100 ease-in-out flex items-center justify-between whitespace-nowrap ${
                                isSideBarCollaped ? "opacity-0" : "opacity-100 "
                              }`}
                            >
                              {link.spaName}
                            </div>
                            {/* Additional Information */}
                            {/* <div className="text-gray-400 font-normal text-xs">
                              {i == 1 && ""}
                            </div> */}
                          </div>
                        </NavLink>
                      ))}
                      {i !== links.length - 1 && (
                        <Divider className="sm:block hidden" />
                      )}
                    </>
                  ))}
                </div>
                <div className="flex flex-col">
                  <Link
                    to="/home"
                    className={`px-2 py-1 rounded-lg m-2 flex space-x-2 items-center text-gray-500 min-h-8`}
                  >
                    <div>
                      <IoIosHelpCircle className="w-5 h-5" />
                    </div>
                    <div
                      className={`flex w-28 justify-between items-center  ${
                        isSideBarCollaped && "hidden"
                      }`}
                    >
                      <div>Ayuda</div>
                    </div>
                  </Link>
                  <Button
                    onClick={() => {
                      localStorage.removeItem("token");
                      window.location.href = "/login";
                    }}
                  >
                    <PiSignOutBold />
                    <div className={isSideBarCollaped && "hidden"}>Salir</div>
                  </Button>
                </div>
              </div>
            </div>
            <Content
              className="overflow-y-auto py-1 px-2 sm:ml-2 sm:py-3 sm:px-6 bg-white outline outline-gray-200 h-[100vh] sm:h-[96vh] sm:rounded-xl"
              style={
                {
                  // height: "96vh",
                  // borderRadius: `${borderRadius}px ${borderRadius}px ${borderRadius}px ${borderRadius}px`,
                }
              }
            >
              <Header className="flex justify-between space-x-2 bg-transparent items-center p-0 h-8">
                <div className="flex justify-start space-x-5 items-center">
                  <Breadcrumb className="hidden sm:block h-6 fixed">
                    {breadcrumbItems.map((item, index) => (
                      <Breadcrumb.Item key={index}>
                        {item.link ? (
                          <Link to={item.link}>{item.name}</Link>
                        ) : (
                          item.name
                        )}
                      </Breadcrumb.Item>
                    ))}
                  </Breadcrumb>
                </div>

                {/* <div className="demo-logo" /> */}
                {/* <Menu
          className="w-full"
          mode="horizontal"
          defaultSelectedKeys={["1"]}
          items={items1}
          // style={{ flex: 1, minWidth: 0 }}
        /> */}
                <div>Mas opciones</div>
              </Header>
              <div className="px-5 py-5">{children}</div>
            </Content>
          </div>
        </Layout>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Created by Felipe Azúa Zagal.
      </Footer>
    </Layout>
  );
};

export default DashboardLayout;
